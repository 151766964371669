<template>
  <v-card 
    width="100%"
    flat
  >
    <v-row
      no-gutters 
    >
      <v-card-title
        :style="{borderBottom: '1px solid #ccc', width: '100%', height: '60px', fontSize: '2.5vh'}"
      >
        {{ title }}
      </v-card-title>
    </v-row>
    <v-form 
      ref="form"
      v-model="valid"
    >
      <v-row 
        no-gutters
        :style="{height:'88%'}"
        class="pt-2"
      >
        <v-col>          
          <v-row no-gutters>
            <v-col
              sm="3"
              md="3"
              :cols="$vssWidth < 600 ? 4 : 8"
              class="d-flex align-center"
            >
              SMTP:
            </v-col>
            <v-col
              sm="8"
              md="7"
              :cols="$vssWidth < 600 ? 8 : 8"
              class="pt-5"
            >
              <v-text-field
                no
                label="Ex.: smtp.gmail.com"
                value=""
                outlined
                dense
                flat        
                single-line                
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              sm="3"
              md="3"
              :cols="$vssWidth < 600 ? 4 : 8"
              class="d-flex align-center"
            >
              Porta:
            </v-col>
            <v-col
              sm="8"
              md="7"
              :cols="$vssWidth < 600 ? 8 : 8"
              class="pt-5"
            >
              <v-text-field
                label="(Gmail)"
                value=""
                outlined
                dense
                flat         
                single-line     
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              sm="3"
              md="3"
              :cols="$vssWidth < 600 ? 4 : 8"
              class="d-flex align-center"
            >
              Remetente:
            </v-col>
            <v-col
              sm="8"
              md="7"
              :cols="$vssWidth < 600 ? 8 : 8"
              class="pt-5"
            >
              <v-text-field
                label="Ex.: Nome da loja"
                value=""
                outlined
                dense
                flat   
                single-line           
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              sm="3"
              md="3"
              :cols="$vssWidth < 600 ? 4 : 8"
              class="d-flex align-center"
            >
              E-mail do remetente:
            </v-col>
            <v-col
              sm="8"
              md="7"
              :cols="$vssWidth < 600 ? 8 : 8"
              class="pt-5"
            >
              <v-text-field
                label="Ex.: saastec@saastec.com.br"
                value=""
                outlined
                dense
                flat  
                single-line            
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              sm="3"
              md="3"
              :cols="$vssWidth < 600 ? 4 : 8"
              class="d-flex align-center"
            >
              Senha:
            </v-col>
            <v-col
              sm="8"
              md="7"
              :cols="$vssWidth < 600 ? 8 : 8"
              class="pt-5"
            >           
              <v-text-field
                value=""
                outlined
                dense
                flat 
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>    
    <v-row no-gutters>
      <v-col
        sm="10"
        md="9"
        :cols="$vssWidth < 600 ? 10 : 8"
        class="d-flex align-center"
      >
        Utiliza autenticação:
      </v-col>
      <v-col
        sm="2"
        md="3"
        :cols="$vssWidth < 600 ? 2 : 8"
        class="pt-0"
      >
        <v-switch inset />
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props:{
    title:{
      type: String,
      required: true,
      default: '',
    },
  },
  data(){
    return {
      valid: false,

    }
  },
  watch:{
    
  },
  mounted(){

  },
  methods: {
    
  },
}
</script>